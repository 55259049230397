import { eachDayOfInterval, format, parseISO, startOfWeek, subWeeks } from 'date-fns';
import without from 'lodash/without';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { add, assoc, assocPath, compose, indexOf, isEmpty, keys, max, reduce, values } from 'ramda';
import { getI18n } from 'react-i18next';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { isSearchAreaCustomArea } from '@bridebook/toolbox/src/search-suppliers/custom-areas';
import type { Slug } from '@bridebook/toolbox/src/types';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { env } from 'lib/env';
import { PremiumTiers } from 'lib/premium/constants';
import {
  getShortenedPremiumTierName,
  getShortenedSupplierPremiumTierName,
} from 'lib/premium/utils/tiers';
import BookedSupplier from 'lib/statistics/charts/booked-supplier';
import CoupleBudgets from 'lib/statistics/charts/couple-budgets';
import EnquiryViews from 'lib/statistics/charts/enquiry-views';
import getHistoricalRankings from 'lib/statistics/charts/historical-rankings';
import getHistoricalRankingsCompetitors from 'lib/statistics/charts/historical-rankings-competitors';
import ProfileScore from 'lib/statistics/charts/profile-score';
import getProfileViews from 'lib/statistics/charts/profile-views';
import getResponseScoresChartConfig from 'lib/statistics/charts/response-scores';
import ConfigSearchAppearances from 'lib/statistics/charts/search-appearances';
import ReviewsRating from 'lib/statistics/reviews-rating';
import {
  IMonoquery,
  IReviewsRating,
  ISearchRanking,
  IStatisticsHighchartsConfig,
  StatisticsEndpoint,
  SupplierScoreCategories,
  TKeywordInsights,
  TPlotLine,
  TTierChangeByDate,
  TTierChangeHistory,
  TTierChangeRecord,
  TTierChangeSegmentized,
  TYearMonth,
  TYearMonthDay,
} from 'lib/statistics/types';
import {
  buildHistoricalRankingsCompetitorsCategories,
  buildHistoricalRankingsCompetitorsSeries,
  buildSearchAppearancesSeries,
} from 'lib/statistics/utils';
import { getDateFnsLocales } from 'lib/utils/date-fns/format-i18n-date';
import { ChartGranularity, ChartName } from '../constants';

const moment = extendMoment(Moment);

const getOrdinalLocale = () => {
  const language = getI18n().language || 'en-GB';
  return new Intl.PluralRules(language, {
    type: 'ordinal',
  });
};

const getSuffixes = () =>
  new Map([
    ['one', getI18n().t('suffix.first')],
    ['two', getI18n().t('suffix.second')],
    ['few', getI18n().t('suffix.few')],
    ['other', getI18n().t('suffix.other')],
  ]);

export const getNumberOrdinal = (number: number) => {
  const rule = getOrdinalLocale().select(number);
  const suffix = getSuffixes().get(rule);
  return `${number}${suffix}`;
};

export const getOrdinalSuffix = (number: number) => {
  const rule = getOrdinalLocale().select(number);
  return getSuffixes().get(rule);
};

/**
 * Get coordinates for search
 * @method getCoords
 * @param {string} areaSlug - area to get coords for
 * @returns {Object}
 */
export function getCoords(
  areaSlug: string,
): Record<`${'ne' | 'sw'}${'lat' | 'lon'}`, number> | Record<string, never> {
  const lowercased = areaSlug.toLowerCase().trim();
  const formatted = lowercased.replace('the ', '').replace(/ /g, '-');
  switch (formatted) {
    case 'greater-london':
      return {
        swlat: 51.37951649918152,
        swlon: -0.30662753808587695,
        nelat: 51.640201749648625,
        nelon: 0.04356167089849805,
      };
    case 'south-east':
      return {
        swlat: 50.088391203891916,
        swlon: -1.5291751750630738,
        nelat: 52.190315909952886,
        nelon: 1.2723384968119262,
      };
    case 'south-west':
      return {
        swlat: 49.93093527480178,
        swlon: -4.374854278585417,
        nelat: 52.039862543995184,
        nelon: -1.573340606710417,
      };
    case 'west-midlands':
      return {
        swlat: 52.038335546330934,
        swlon: -2.6381877513931613,
        nelat: 53.05379549039736,
        nelon: -1.2374309154556613,
      };
    case 'north-west':
      return {
        swlat: 53.46378192379638,
        swlon: -3.5118494409636014,
        nelat: 54.44633920076728,
        nelon: -2.1110926050261014,
      };
    case 'north-east':
      return {
        swlat: 54.44474212784517,
        swlon: -2.0836267847136014,
        nelat: 55.40431429023347,
        nelon: -0.6828699487761014,
      };
    case 'yorkshire':
      return {
        swlat: 53.34051552243459,
        swlon: -1.5283558679687985,
        nelat: 54.3259415561244,
        nelon: -0.12759903203129852,
      };
    case 'east-midlands':
      return {
        swlat: 52.43188239726838,
        swlon: -1.0082281599868566,
        nelat: 53.438318174125854,
        nelon: 0.39252867595064345,
      };
    case 'east-of-england':
      return {
        swlat: 51.811826248482895,
        swlon: 0.020977998202624804,
        nelat: 52.83245917380004,
        nelon: 1.4217348341401248,
      };
    case 'scotland':
      return {
        swlat: 54.869360553269686,
        swlon: -6.763697882604788,
        nelat: 58.533021564124255,
        nelon: -1.160670538854788,
      };
    case 'wales':
      return {
        swlat: 51.29875530718421,
        swlon: -5.226218374114978,
        nelat: 53.33980086898895,
        nelon: -2.4247047022399784,
      };
    case 'northern-ireland':
      return {
        swlat: 53.668911382265286,
        swlon: -8.03036700000007,
        nelat: 55.601491476793306,
        nelon: -5.228853328125069,
      };
    default:
      return {};
  }
}

interface IGetSearchRankingQueryArgs {
  type: string;
  supplierCounty: string;
  searchArea: string;
  supplierCountry: string;
  supplierId: string;
  compareSupplierIds?: Array<string> | null;
  isCompetitorsFetch: boolean;
  countryCode?: string;
}

/**
 * Build query for search request to API
 * @method getSearchRankingQueryUK
 * @param {Object} supplier - supplier object for data
 * @returns {Object}
 */
export const getSearchRankingQueryUK = ({
  searchArea,
  supplierCountry,
  supplierId,
  compareSupplierIds,
  isCompetitorsFetch,
  countryCode = '',
  type,
}: IGetSearchRankingQueryArgs): Record<string, any> => {
  const actualCountry = isCompetitorsFetch ? 'uk' : supplierCountry || 'uk';

  const area = isCompetitorsFetch
    ? 'uk'
    : searchArea
        .replace(/-/g, ' ')
        .replace(/\+|%2B/g, ' ')
        .replace(/,|%2C/g, '');

  const isCustomArea = isSearchAreaCustomArea(searchArea);

  return {
    type,
    area,
    searchType: isCompetitorsFetch ? 'country' : isCustomArea ? 'customArea' : 'county',
    country: actualCountry,
    countryCode,
    county: isCompetitorsFetch ? '' : searchArea,
    isLoggedIn: true,
    supplierRankId: compareSupplierIds || supplierId,
    ...getCoords(isCompetitorsFetch ? 'uk' : searchArea),
    isCompetitorsFetch,
  };
};

/**
 * Build query for search request to API
 * @method getSearchRankingQuery
 * @param {Object} supplier - supplier object for data
 * @returns {Object}
 */
export const getSearchRankingQuery = ({
  searchArea,
  supplierCountry,
  supplierId,
  compareSupplierIds,
  isCompetitorsFetch,
  countryCode = '',
  type,
}: IGetSearchRankingQueryArgs): Record<string, any> => {
  let actualCountryOrCounty;
  if (isCompetitorsFetch) {
    actualCountryOrCounty = countryCode;
    // if !isCompetitorsFetch, for DE and US we use adminArea[1],
  } else if (countryCode === CountryCodes.US) {
    actualCountryOrCounty = supplierCountry || countryCode;
    // for other countries (including FR) we use adminArea[0]
  } else {
    actualCountryOrCounty = searchArea;
  }

  const area = isCompetitorsFetch
    ? countryCode
    : searchArea
        .replace(/-/g, ' ')
        .replace(/\+|%2B/g, ' ')
        .replace(/,|%2C/g, '');

  return {
    type,
    area,
    searchType: isCompetitorsFetch ? 'country' : 'county',
    country: '',
    countryCode,
    county: isCompetitorsFetch ? '' : actualCountryOrCounty,
    isLoggedIn: true,
    supplierRankId: compareSupplierIds || supplierId,
    ...getCoords(isCompetitorsFetch ? countryCode : searchArea),
    isCompetitorsFetch,
  };
};

const getType = (type: ChartName): IStatisticsHighchartsConfig => {
  switch (type) {
    case ChartName.bookedSupplier:
      return BookedSupplier();
    case ChartName.searchAppearances:
      return ConfigSearchAppearances();
    case ChartName.profileViews:
      return getProfileViews();
    case ChartName.enquiryViews:
      return EnquiryViews();
    case ChartName.coupleBudgets:
      return CoupleBudgets;
    case ChartName.responseScores:
      return getResponseScoresChartConfig();
    case ChartName.profileScore:
      return ProfileScore;
    case ChartName.historicalRankings:
      return getHistoricalRankings();
    case ChartName.historicalRankingsCompetitors:
      return getHistoricalRankingsCompetitors();
    default:
      throw new Error('Add your case!');
  }
};

export const get12WeeksRange = () => {
  const sdate = subWeeks(new Date(), 12);
  const edate = new Date();

  return eachDayOfInterval({ start: sdate, end: edate }).filter(
    (d) => d.valueOf() === startOfWeek(d, { weekStartsOn: 1 }).valueOf(),
  );
};

/*
  Format array of string dates to ie 15 Mar, 25 Apr, 16 Jun
 */
export const formatWeeklyDate = (data: (string | Date)[]) =>
  data.map((c) =>
    format(typeof c === 'string' ? parseISO(c) : c, 'd MMM', {
      locale: getDateFnsLocales(getI18n().language),
    }),
  );

/*
  Format array of string dates to ie Jan, Feb, Mar
 */
export const formatMonthlyDate = (data: (string | Date)[]) => {
  const dateFnsLocale = getDateFnsLocales(getI18n().language);
  const getShortMonth = (index: number) =>
    dateFnsLocale.localize.month(index, { width: 'abbreviated' });

  const months = {
    Jan: getShortMonth(0),
    Feb: getShortMonth(1),
    Mar: getShortMonth(2),
    Apr: getShortMonth(3),
    May: getShortMonth(4),
    Jun: getShortMonth(5),
    Jul: getShortMonth(6),
    Aug: getShortMonth(7),
    Sep: getShortMonth(8),
    Oct: getShortMonth(9),
    Nov: getShortMonth(10),
    Dec: getShortMonth(11),
  };

  const monthList = data.map((c) =>
    format(typeof c === 'string' ? parseISO(c) : c, 'MMM'),
  ) as (keyof typeof months)[];

  return monthList.map((month) => months[month]);
};

interface IExtractData {
  type: ChartName;
  data: Record<string, any>;
  supplierTier?: number;
  granularity?: ChartGranularity;
  supplierType?: Slug;
}

/**
 * Get highcharts config for search appearances from data object
 * @method extractData
 * @param type
 * @param {Object | null} data - data to extract config from
 * @param supplierTier
 * @param granularity
 * @returns {Object}
 */
export const extractData = ({
  type,
  data,
  supplierTier,
  granularity = ChartGranularity.month,
  supplierType = 'venue',
}: IExtractData): IStatisticsHighchartsConfig => {
  const Config = getType(type);
  let categories = keys(data);
  let setCategories = true;
  let seriesData = values(data);
  const updateFns = [] as const;
  let series = null;

  switch (type) {
    case ChartName.searchAppearances:
    case ChartName.bookedSupplier:
    case ChartName.profileViews:
    case ChartName.enquiryViews:
      if (granularity === ChartGranularity.week) {
        const spliceAt = seriesData.length - 12; // last 12 weeks
        seriesData = seriesData.splice(spliceAt);
        categories = categories.splice(spliceAt);
      }

      series =
        type === ChartName.searchAppearances
          ? buildSearchAppearancesSeries(Config, seriesData, supplierTier, supplierType)
          : null;
      categories =
        granularity === ChartGranularity.month
          ? formatMonthlyDate(categories)
          : formatWeeklyDate(categories);
      break;
    case ChartName.coupleBudgets:
      seriesData = categories.map((c) => ({
        y: data[c],
        name: c,
        legendIndex: ['< £5k', '£5k - £10k', '£10k - £15k', '£15k - £20k', '> £20k'].indexOf(c),
      }));
      setCategories = false;
      break;
    case ChartName.profileScore:
      setCategories = false;
      break;
    case ChartName.historicalRankingsCompetitors:
      series = buildHistoricalRankingsCompetitorsSeries(Config, seriesData);
      categories = buildHistoricalRankingsCompetitorsCategories(seriesData);
      break;
    case ChartName.historicalRankings:
      categories = formatWeeklyDate(categories);
      break;
    case ChartName.responseScores:
      seriesData = categories.map((c) => ({
        // otherwise the chart looks broken
        y: data[c].responseScore > 0 ? data[c].responseScore : 0.333333,
        name: c,
        color: data[c].color,
      }));
      break;
    default:
  }

  if (setCategories) {
    updateFns.push(assocPath(['xAxis', 'categories'], categories));
  }

  if (!series) {
    series = [
      {
        ...Config.series[0],
        data: seriesData,
      },
    ];
  }

  return compose(
    ...updateFns,
    assoc('series', series),
    assoc('loaded', true),
  )(Config) as IStatisticsHighchartsConfig;
};

export const getInitialProps = (type: ChartName): IStatisticsHighchartsConfig => {
  const Config = getType(type);
  // Defining start dates/end dates.
  const sdate = moment().subtract(1, 'years').format('YYYY-MM-DD');
  const edate = moment().subtract(1, 'months').format('YYYY-MM-DD');

  const months = Array.from(moment.range(sdate, edate).by('month'));
  const placeholderKeys = months.map((m) => m.format('YYYY-MM'));
  const placeholderData = placeholderKeys.map(() => 0);

  return compose(
    assocPath(['xAxis', 'categories'], placeholderKeys),
    assoc('series', [
      {
        ...Config.series[0],
        data: placeholderData,
      },
    ]),
    assoc('loaded', true),
  )(Config) as IStatisticsHighchartsConfig;
};

/**
 * Returns search position for actual supplier
 * @method getSingleSupplierSearchRanking
 * @param {Array<Object>} searchRanking - search ranking array
 * @returns {Number} - search position
 */
export const getSingleSupplierSearchRanking = (searchRanking: ISearchRanking[]): number => {
  let supplierSearchRanking = 0;
  if (searchRanking && searchRanking[0] && !isNaN(searchRanking[0].position)) {
    // because ranking starts with "0" but "-1" is for "out of range" (>500)
    supplierSearchRanking =
      searchRanking[0].position > -1 ? searchRanking[0].position : searchRanking[0].position;
  }

  return supplierSearchRanking;
};

/**
 * Matches given endpoint against prop used in reducer
 * @method mapEndpointToProp
 * @param {String} endpoint - endpoint to map to prop
 * @returns {String} - mapped prop
 */
const mapEndpointToProp = (endpoint: StatisticsEndpoint): string => {
  const endpointsMap: Record<StatisticsEndpoint, any> = {
    'couple-budgets': 'coupleBudgets', // mixpanel
    'competitor-list-shortlists': 'competitorList', // mixpanel + venuerex
    'keyword-insights': 'keywordInsights', // mixpanel
  };

  const prop = endpointsMap[endpoint];
  if (!prop) throw new Error('Cannot map analytics endpoint');

  return prop;
};

export const getMixpanelQueryString = () =>
  env.ENABLE_MIXPANEL_FETCH === 'true' ? `enableMixpanelFetch=true` : '';

/**
 * Fetches single statistics value from corresponding endpoint and transforms it accordingly
 * @method fetchStatisticsValue
 * @param {String} supplierId - current supplier id
 * @param {String} endpoint - endpoint to fetch data from
 * @returns {Promise} - promise returning the fetched/transformed data
 */
export const fetchStatisticsValue = async (
  supplierId: string,
  endpoint: StatisticsEndpoint,
): Promise<{ name: string; value: any }> => {
  const mixpanelQuery = getMixpanelQueryString();
  const res = await authenticatedFetch(
    `/api/supplier/${supplierId}/statistics/${endpoint}${mixpanelQuery ? `?${mixpanelQuery}` : ''}`,
  );

  let response = {};
  if (res.status === 200) {
    response = await res.json();
  } else {
    throw new Error(res.statusText);
  }

  let value;
  switch (endpoint) {
    case 'couple-budgets':
      value = extractData({ type: ChartName.coupleBudgets, data: response });
      break;
    default:
      value = response || {};
  }

  const name = mapEndpointToProp(endpoint);

  return { name, value };
};

interface IFetchSearchRankingArgs {
  supplierId: string;
  supplierCounty: string;
  supplierCustomArea?: string;
  additionalAreas?: Array<string>;
  supplierCountry: string;
  compareSupplierIds?: Array<string>;
  isCompetitorsFetch?: boolean;
  countryCode?: string;
  type?: string;
}

/**
 * Fetches search ranking for one or more suppliers
 * @method fetchSearchRanking
 * @param {Object} supplier - current supplier object
 * @param {Array<string>} compareSupplierIds - competitors list
 * @returns {Object} - search ranking data
 */
export const fetchSearchRanking = async ({
  supplierId,
  supplierCounty,
  supplierCustomArea,
  additionalAreas = [],
  supplierCountry,
  compareSupplierIds,
  isCompetitorsFetch = false,
  countryCode = '',
  type = 'venue',
}: IFetchSearchRankingArgs): Promise<ISearchRanking[]> => {
  const input = {
    supplierId,
    supplierCounty,
    supplierCustomArea,
    additionalAreas,
    supplierCountry,
    compareSupplierIds,
    isCompetitorsFetch,
    countryCode,
    type,
  };

  const res = await fetch(`/api/search/supplier-rank`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.json();
};

/**
 * Assigns a supplier category based on it's profile score
 * @method getSupplierScoreCategory
 * @param {string} score
 * @returns {string} - profile category name
 */
export const getSupplierScoreCategory = (score: number): SupplierScoreCategories => {
  switch (true) {
    case score === 0.9 || score > 0.9:
      return SupplierScoreCategories.Expert;
    case score === 0.5 || score > 0.5:
      return SupplierScoreCategories.Intermediate;
    default:
      return SupplierScoreCategories.Beginner;
  }
};

/**
 * Returns translated name of score category
 * @param score
 */
export const getSupplierScoreCategoryLabel = (score: number): string => {
  const category = getSupplierScoreCategory(score);
  switch (true) {
    case category === SupplierScoreCategories.Expert:
      return getI18n().t('analytics:scoreExpert');
    case category === SupplierScoreCategories.Intermediate:
      return getI18n().t('analytics:scoreIntermediate');
    case category === SupplierScoreCategories.Beginner:
    default:
      return getI18n().t('analytics:scoreBeginner');
  }
};

export const fetchUkHistoricalRankings = async (id: string) =>
  authenticatedFetch(`/api/supplier/${id}/statistics/uk-historic-rankings`).then((r) =>
    r.json(),
  ) as Promise<Record<string, Record<string, number>>>;

export const fetchHistoricalRankings = async (ids: string[], county: string) => {
  const res = await authenticatedFetch(`/api/statistics/historic-rankings`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ supplierIds: ids, county }),
  });

  return res.json();
};

interface IGetCompetitorsDataArgs {
  supplierId: string;
  supplierCounty: string;
  supplierCountry: string;
  competitorList: Record<string, any>;
  countryCode: CountryCodes;
}

/**
 * Combines competitors data from mixpanel and elastic for use in cms analytics
 * @method getCompetitorsData
 * @param {Object} supplier - current supplier object
 * @returns {Object} - combined data
 */
export const getCompetitorsData = async ({
  supplierId,
  supplierCounty,
  supplierCountry,
  competitorList,
  countryCode,
}: IGetCompetitorsDataArgs): Promise<Record<string, any>[]> => {
  const compareSupplierIds = keys(competitorList);
  const searchRankingPromise = fetchSearchRanking({
    supplierId,
    supplierCounty,
    supplierCountry,
    compareSupplierIds,
    isCompetitorsFetch: true,
    countryCode,
  });

  const historicalRankingsPromise = fetchHistoricalRankings(compareSupplierIds, '');

  const [searchRanking, historicalRankings] = await Promise.all([
    searchRankingPromise,
    historicalRankingsPromise,
  ]);

  let data: Record<string, any>[] = [];

  if (!isEmpty(competitorList)) {
    data = searchRanking.map((ranking) => {
      const shortlisted = competitorList[ranking.id] || 0;

      return {
        ...ranking,
        shortlisted,
        profileScoreCategory: getSupplierScoreCategory(ranking.profileScore),
        historicalRankings: historicalRankings[ranking.id] || {},
      };
    });
  }

  return data;
};

export const compileKeywordInsightData = (data: TKeywordInsights) =>
  keys(data).map((item) => ({
    name: item,
    frequency: data[item],
  }));

export const fetchReviewsRating = async (supplierId: string): Promise<IReviewsRating> => {
  const url = `/api/supplier/${supplierId}/statistics/reviews-rating`;
  const response = await authenticatedFetch(url);

  if (response.status === 200) {
    const data = await response.json();

    return data || ReviewsRating;
  }

  throw new Error(response.statusText);
};

export const segmentizeTierChangeHistory = (
  tierChangeByDate: TTierChangeByDate,
  granularity: ChartGranularity,
) => {
  const segmentizedTierChangeHistory: TTierChangeSegmentized<TYearMonthDay | TYearMonth> = {};

  for (const key of keys(tierChangeByDate)) {
    let segmentKey;
    if (granularity === ChartGranularity.week) {
      segmentKey = format(startOfWeek(parseISO(key), { weekStartsOn: 1 }), 'yyyy-MM-dd');
    } else if (granularity === ChartGranularity.month) {
      segmentKey = format(parseISO(key), 'yyyy-MM');
    } else {
      throw new Error('Provided granularity is not supported');
    }
    if (!segmentizedTierChangeHistory[segmentKey]) {
      segmentizedTierChangeHistory[segmentKey] = {
        all: [...tierChangeByDate[key].all],
        last: tierChangeByDate[key].last,
      };
    } else {
      segmentizedTierChangeHistory[segmentKey].all.push(...tierChangeByDate[key].all);
      segmentizedTierChangeHistory[segmentKey].last = tierChangeByDate[key].last;
    }
  }

  return segmentizedTierChangeHistory;
};

interface TierLabelProps {
  tierChange: PremiumTiers;
  supplierType?: Slug;
}

export const getMovedToTierLabel = ({ tierChange, supplierType = 'venue' }: TierLabelProps) => {
  const i18n = getI18n();
  return `${i18n.t('analytics:movedTo', 'Moved to')} ${
    supplierType === 'venue'
      ? getShortenedPremiumTierName(tierChange)
      : getShortenedSupplierPremiumTierName(tierChange)
  }`;
};

/*
  Get tier change plot lines based on series data granularity and history.
  It's used for charts to show  when supplier changed tier for example from tier_3 to tier_2
 */
interface TierChangePlotLinesProps {
  seriesData: Record<string, any>;
  granularity: ChartGranularity;
  tierChangeData: TTierChangeHistory;
  supplierType?: Slug;
}

export const getTierChangePlotLines = ({
  seriesData,
  granularity,
  tierChangeData,
  supplierType = 'venue',
}: TierChangePlotLinesProps) => {
  if (!tierChangeData.loaded)
    throw new Error('Cannot get plot lines if tier change data is not loaded');

  let categories = keys(seriesData);
  if (granularity === ChartGranularity.week) {
    const spliceAt = values(seriesData).length - 12; // last 12 weeks
    categories = categories.splice(spliceAt);
  }

  let segmentizedTierChangeData: TTierChangeSegmentized<string>;
  if (granularity === ChartGranularity.month) {
    segmentizedTierChangeData = tierChangeData.tierChangeByMonth;
  } else if (granularity === ChartGranularity.week) {
    segmentizedTierChangeData = tierChangeData.tierChangeByWeek;
  } else {
    throw new Error('Granularity is not supported');
  }
  return without(
    categories.map((category, index) => {
      const tierChange = segmentizedTierChangeData[category];
      if (tierChange === undefined) return undefined;
      else {
        return {
          value: index,
          dashStyle: 'Dash',
          width: 1,
          color: '#d33',
          label: {
            text: getMovedToTierLabel({
              tierChange: tierChange.last.tierAfterChange,
              supplierType,
            }),
          },
        };
      }
    }),
    undefined,
  ) as TPlotLine[];
};

export const fetchMonoquery = async (supplierId: string): Promise<IMonoquery['data']> => {
  const response = await authenticatedFetch(ApiEndpoint.statistics.monoquery(supplierId));

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(response.statusText);
};

export const fetchTierChangeHistory = async (
  supplierId: string,
): Promise<{ results: TTierChangeRecord[] }> => {
  const response = await authenticatedFetch(`/api/supplier/${supplierId}/tier-change-history`);

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(response.statusText);
};

export const numToPercentage = (value: number) => `${value}%`;

export const getPercetageOf = (amount: number, of: number) => Math.round((amount / of) * 100);

export const getPercentageDifferential = (amount: number, of: number) =>
  of === 0 ? 0 : getPercetageOf(amount, of) - 100;

export const getPercentagesOfTotal = (total: number, amounts: number[]) => {
  const getPercentage = (amount: number) => Math.round(getPercetageOf(amount, total));

  const list: number[] = [];

  amounts.forEach((item) => {
    list.push(getPercentage(item));
  });

  // get how much excess percentage we get in total due to Math.round
  const excess = list.reduce((a, b) => a + b, 0) - 100;

  // get index of the largest percent value
  if (excess > 0) {
    const indexOfLargest = indexOf(reduce(max, -Infinity, list), list);

    // substract excess percentage from the largest value
    list[indexOfLargest] = list[indexOfLargest] - excess;
  }

  return list.map((item) => numToPercentage(item));
};

// get percentage amount of each array value from total array values sum
export const getPercentagesOfTotalSum = (amounts: number[]): string[] => {
  const total = reduce(add, 0, amounts);

  return getPercentagesOfTotal(total, amounts);
};
export { default as buildHistoricalRankingsCompetitorsCategories } from './build-historical-rankings-competitors-categories';
export { default as buildHistoricalRankingsCompetitorsSeries } from './build-historical-rankings-competitors-series';
export { default as buildSearchAppearancesSeries } from './build-search-appearances-series';
export {
  calculateVenueSearchAppearancesValue,
  calculateSupplierSearchAppearancesValue,
} from './calculate-search-appearances-value';
export { default as getTimeseriesLastMonthTotal } from './get-timeseries-last-month-total';
export { default as segmentizeTimeseriesData } from './segmentize-timeseries-data';
