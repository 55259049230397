import { PremiumTiers } from '@bridebook/toolbox';

/**
 * Returns the maximum number of additional counties a supplier can have based on their tier.
 */
export const getAdditionalCountiesLimit = ({
  supplierTier,
  isVenue,
  isAdmin,
}: {
  supplierTier: PremiumTiers;
  isVenue?: boolean;
  isAdmin?: boolean;
}) => {
  // Limits for venues
  if (isVenue) {
    if (supplierTier >= PremiumTiers.Tier_3) {
      return 5;
    }

    return 0;
  }

  // Limits for suppliers
  if (supplierTier >= PremiumTiers.Tier_2) {
    return isAdmin ? 16 : 2; // LIVE-20576: 16 to match all DE counties (lands), 2 if not admin
  }

  return 0;
};
