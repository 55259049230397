import getServerSideLocale from 'i18n/get-server-side-locale';
import { IFilterLabelProps } from '@bridebook/analytics';
import type { INonce_Role } from '@bridebook/models/source/models/Nonces.types';
import { ISupplierUser } from '@bridebook/models/source/models/SupplierUsers.types';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { identifySupplier, supplierPropertiesGeneric } from 'lib/analytics-utils';
import { supplierListingPropertiesGeneric } from 'lib/analytics-utils/supplierListingPropertiesGeneric';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { CriticalCMSEvents } from '../analyticsTypes';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.testEnquirySupplierEmail;
  delete newProps.testEnquirySupplierAccountEmail;
  delete newProps.firstName;
  delete newProps.lastName;
  delete newProps.userAccessControl;
  return newProps;
};

const collaboratorPropertiesGeneric = (role: INonce_Role, inviteUrl?: string) => ({
  collaboratorPermissions: role,
  collaboratorInvite: inviteUrl,
});

const identifySupplierClaim = (
  { supplier }: { supplier: ISupplier; user: ISupplierUser },
  getState: () => IApplicationState,
) => {
  const { photos, videos, testimonials } = supplier?.counters || {};

  const state = getState();
  const props = {
    noOfSupplierPhotos: photos || 0,
    noOfSupplierVideos: videos || 0,
    // noOfSupplierRecommendations: (ourPreferredSupplierList && ourPreferredSupplierList.length) || 0,
    noOfSupplierEndorsements: testimonials || 0,
    ...supplierListingPropertiesGeneric(state),
    ...supplierPropertiesGeneric(state),
  };
  identifySupplier({ supplier, props, claimed: true });
};

export default function usersAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { payload } = action;

  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'CMS',
    filterLabelProps,
  );

  switch (action.type) {
    case 'SAVE_USER_SETTINGS_SUCCESS_ANALYTICS': {
      const { fields } = payload || {};
      const {
        users: { viewer },
      } = getState();
      const props = {
        firstName: fields.name[0],
        lastName: fields.name[1],
        phone: fields.phone,
        supplierEmail: fields.email,
      };
      const trackProps = {
        event: 'Supplier edited user details',
        category: 'User details',
        ...props,
      };

      identify(props, trackProps, { userId: viewer?.id });
      break;
    }

    case 'SAVE_USER_SETTINGS_ERROR_ANALYTICS': {
      const { fields, error } = payload || {};
      const props = {
        firstName: fields.name[0],
        lastName: fields.name[1],
        phone: fields.phone,
        supplierEmail: fields.email,
      };

      track({
        event: 'Supplier failed to edit user details',
        category: 'User details',
        reasonSupplierFailedToEditUserDetails: error.message,
        ...props,
      });
      break;
    }

    case 'CONTACT_SUPPLIER_ANALYTICS': {
      const state = getState();
      identify(
        {},
        {
          event: 'Supplier triggered a test enquiry',
          ...supplierPropertiesGeneric(state),
          ...supplierListingPropertiesGeneric(state),
        },
      );
      break;
    }

    case 'CLAIMED_SUPPLIER_PROFILE': {
      const { user } = payload;
      const state = getState();
      const {
        users: { userAccessControl },
        auth: { collaboratorInvite },
      } = state;

      const users = collaboratorInvite?.inviteSupplier?.users?.length || 0;
      const isOtherUsers = users > 0;
      const event = isOtherUsers
        ? CriticalCMSEvents.COLLABORATED_ON_SUPPLIER_PROFILE
        : 'Claimed supplier profile';

      getServerSideLocale(user.id).then((serverLocale) =>
        identify(
          {},
          {
            event,
            ...collaboratorPropertiesGeneric(
              collaboratorInvite?.invite?.role || 'admin',
              collaboratorInvite?.inviteUrl,
            ),
            ...supplierPropertiesGeneric(state, serverLocale),
            category: isOtherUsers ? 'Supplier linked accounts' : 'Registration',
            userAccessControl,
          },
          { userId: user.id },
        ),
      );

      identifySupplierClaim(
        { supplier: collaboratorInvite.inviteSupplier, viewer: user },
        getState,
      );
      break;
    }

    case 'FAILED_TO_CLAIM_SUPPLIER_PROFILE': {
      const {
        users: { userAccessControl },
        auth: { collaboratorInvite },
      } = getState();

      const isCollaboration = collaboratorInvite?.invite?.collaborator;
      const event = isCollaboration
        ? 'Failed to collaborate on supplier profile'
        : 'Failed to claim supplier profile';

      track({
        event,
        ...supplierPropertiesGeneric(getState()),
        category: isCollaboration ? 'Supplier linked accounts' : 'Registration',
        userAccessControl,
      });
      break;
    }

    case 'LOADED_SUPPLIER_ANALYTICS':
    case AccessControlActionTypes.SWITCH_PERMISSION_SUCCESS: {
      const { user, userAccessControl } = payload || {};
      const supplier = getState().supplier.supplier;
      if (!supplier) break;

      const viewer = user || getState().users.viewer;

      track({
        event: 'Loaded supplier profile on CMS',
        category: 'Supplier linked accounts',
        userAccessControl,
        ...supplierPropertiesGeneric(getState()),
      });

      identifySupplierClaim({ supplier, viewer }, getState);
      break;
    }
  }
}
