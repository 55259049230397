import { AuthCredential, OAuthCredential } from 'firebase/auth';
import type { INonce_Role } from '@bridebook/models/source/models/Nonces.types';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { BridebookError } from 'lib/types';
import { IChangeAuthEmailForm } from './changeAuthEmailForm';

export enum AuthProviders {
  ANONYMOUS = 'anonymous',
  FACEBOOK = 'facebook.com',
  GOOGLE = 'google.com',
  PASSWORD = 'password',
}

export interface IFirebaseError {
  message: string;
  code: string;
  credential?: AuthCredential | OAuthCredential;
  email?: string;
}

export type AuthBridebookError = BridebookError & IFirebaseError & { provider?: AuthProviders };

export type FirebaseErrorCodes =
  | 'auth/email-already-in-use'
  | 'auth/account-exists-with-different-credential'
  | 'auth/invalid-email'
  | 'auth/wrong-password'
  | 'auth/credential-already-in-use'
  | 'auth/user-not-found'
  | 'auth/network-request-failed'
  | 'auth/too-many-requests'
  | 'auth/user-token-expired'
  | 'auth/invalid-action-code';

export interface IAuthFields {
  email: string;
  password: string;
}

export interface IInvite {
  id: string;
  slug: string;
  role?: INonce_Role;
}

export interface ICollaboratorInviteType {
  invite: IInvite | null;
  inviteExpired: boolean;
  inviteSupplier: ISupplier | null;
  inviteUrl: string;
  nonceId: string;
  nonceSecret: string;
}

export interface ChangeAuthEmailForm {
  disabled: boolean;
  error: BridebookError | null;
  fields: {
    oldEmail: string;
    newEmail: string;
    password: string;
  };
}

export interface AuthForm {
  disabled: boolean;
  error: AuthBridebookError | null;
  fields: {
    email: string;
    password: string;
  };
}

export interface Fields {
  oldEmail: string;
  newEmail: string;
  password: string;
  lat?: any;
  lng?: any;
}

export interface IAuthState {
  form: AuthForm;
  changeAuthEmailForm: IChangeAuthEmailForm;
  collaboratorInvite: ICollaboratorInviteType;
  editorInitials: Record<string, any> | null;
  isAdmin: boolean;
  adminACLLoaded: boolean;
}
