import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import {
  AddedTeamChatGroupAnalyticsAction,
  DeletedTeamChatGroupAnalyticsAction,
  TeamChatAnalyticsActionTypes,
} from 'lib/team-chat/analytics/action-types';
import { Action, IApplicationState } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function teamChatAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');

  switch (action.type) {
    case TeamChatAnalyticsActionTypes.ADDED_CMS_CHAT_GROUP_ANALYTICS: {
      const { payload: cmsChatGroupName } = action as AddedTeamChatGroupAnalyticsAction;

      track({
        event: 'Added CMS chat group',
        ...supplierPropertiesGeneric(getState()),
        cmsChatGroupName,
      });
      break;
    }

    case TeamChatAnalyticsActionTypes.DELETED_CMS_CHAT_GROUP_ANALYTICS: {
      const { payload: cmsChatGroupName } = action as DeletedTeamChatGroupAnalyticsAction;

      track({
        event: 'Deleted CMS chat group',
        ...supplierPropertiesGeneric(getState()),
        cmsChatGroupName,
      });
      break;
    }

    case TeamChatAnalyticsActionTypes.ADDED_ATTACHMENT_TO_CMS_CHAT_ANALYTICS: {
      track({
        event: 'Added attachment to CMS chat',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }
  }
}
