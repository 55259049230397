import type { ICollaboratorInviteType } from 'lib/auth/types';

const CollaboratorInvite: ICollaboratorInviteType = {
  nonceId: '',
  nonceSecret: '',
  inviteExpired: false,
  inviteUrl: '',
};

export default CollaboratorInvite;
