import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { PremiumTiers } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { getAdditionalCountiesLimit } from 'lib/multi-county/get-additional-counties-limit';

/**
 * Returns current number of slots for additional counties,
 * and maximum slots available for a given tier.
 */
export const getAdditionalCountiesSlots = ({
  isVenue,
  supplierTier,
  additionalAreasSlots = 0,
  isAdmin,
}: {
  isVenue: boolean;
  supplierTier: PremiumTiers;
  additionalAreasSlots: ISupplier['additionalAreasSlots'];
  isAdmin?: boolean;
}) => {
  const maxSlotsPerTier = getAdditionalCountiesLimit({
    supplierTier,
    isVenue,
    isAdmin,
  });

  // Slots for venues are controlled by the number of purchased items, for suppliers it's based on the tier
  return isVenue ? additionalAreasSlots : maxSlotsPerTier;
};
