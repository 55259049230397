import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { ICollaborator } from 'lib/access-control/types';
import { getAdditionalCountiesLimit as _getAdditionalCountiesLimit } from 'lib/multi-county/get-additional-counties-limit';
import { getAdditionalCountiesSlots as _getAdditionalCountiesSlots } from 'lib/multi-county/get-additional-counties-slots';
import { PremiumTiers } from 'lib/premium/constants';
import { IApplicationState } from 'lib/types';
import { getIsViewerLoaded } from 'lib/users/selectors';

export * from './contact';
export * from './experience';

export const getAdmin = (state: IApplicationState) => state.admin;
export const getSupplierError = (state: IApplicationState) => state.supplier.error;
export const getSupplier = (state: IApplicationState) => state.supplier.supplier;
export const getSupplierId = (state: IApplicationState) => state.supplier.supplier?.id;
export const getSupplierPublicId = (state: IApplicationState) => state.supplier.supplier?.publicId;
export const getSupplierLoaded = (state: IApplicationState) => state.supplier.loaded;
export const getSupplierAdmin = (state: IApplicationState) => state.admin.supplierAdminObject;
export const getSupplierScore = (state: IApplicationState) => state.admin.supplierAdminScore;
export const getPremium = (state: IApplicationState) => state.admin.supplierAdminObject?.premium;
export const getLastMonthEnquiries = (state: IApplicationState) =>
  state.enquiries.numStatuses.lastMonth ?? 0;
const collaborators = (state: IApplicationState) => state.supplier.collaborators;
export const getViewerEmail = (state: IApplicationState) => state.users.viewer?.contacts?.email;
export const getViewerId = (state: IApplicationState) => state.users.viewer?.id;
export const getViewerRole = (state: IApplicationState) =>
  state.users.activeSupplierAccessControl?.role;
export const getActiveSupplierAccessControlId = (state: IApplicationState) =>
  state.users.activeSupplierAccessControl?.id;
export const getAdditionalCounties = (state: IApplicationState) =>
  state.supplier.supplier?.additionalAreas || [];
const _getAdditionalAreasSlots = (state: IApplicationState) =>
  state.supplier.supplier?.additionalAreasSlots ?? 0;
export const getSupplierType = (state: IApplicationState) => state.supplier?.supplier?.type?.[0];
export const getSupplierProSubscriptionId = (state: IApplicationState) =>
  state.admin.supplierAdminChargebee?.supplierProSubscriptionId;
export const getSupplierUsers = (state: IApplicationState) => state.supplier.supplier?.users || [];
export const getSupplierUnsaved = (state: IApplicationState) => state.supplier.unsaved;
export const getGalleryChangesUnsaved = (state: IApplicationState) =>
  state.supplier.galleryChangesUnsaved;
export const getSupplierFormSectionsUpdates = (state: IApplicationState) =>
  state.supplier.formSectionsUpdates;
export const getSupplierUnmodified = (state: IApplicationState) =>
  state.supplier.supplierUnmodified;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

/**
 * Used to show content when all the user and supplier data has been loaded
 */
export const getIsSupplierReady = createSelector(
  [getSupplier, getSupplierLoaded, getSupplierAdmin, getIsViewerLoaded],
  (supplier, supplierLoaded, supplierAdmin, viewerLoaded) =>
    Boolean(supplier && supplierLoaded && supplierAdmin && viewerLoaded),
);

const _supplierSlug = createSelector([getSupplier], (supplier) => supplier?.type?.[0] || 'venue');
export const getSupplierSlug = createSelector([_supplierSlug], (supplierSlug) => supplierSlug);

export const getIsVenue = createSelector(
  [getSupplierSlug],
  (supplierSlug) => supplierSlug === 'venue',
);

export const getIsPhotographer = createSelector(
  [getSupplierSlug],
  (supplierSlug) => supplierSlug === 'photo',
);

export const getIsGroup = createSelector(
  [getSupplierSlug],
  (supplierSlug) => supplierSlug === 'group',
);

export const getSupplierTier = createSelector(
  [getPremium],
  (premium): PremiumTiers => premium?.tier || PremiumTiers.Tier_0,
);

export const getSupplierFullAddress = createSelector(
  [getSupplier],
  (supplier) => supplier?.address,
);

export const getSupplierCurrencyCode = createSelector(
  getSupplier,
  (supplier) => supplier?.l10n?.currency || 'GBP',
);

// Get supplier specific props
export const getSupplierDetails = createSelector([getSupplierType, getSupplier], (type, supplier) =>
  type ? supplier?.typeDetails?.[type] : null,
);

export const getVenueDetails = createSelector([getSupplier, getIsVenue], (supplier, isVenue) =>
  isVenue ? supplier?.typeDetails?.venue : null,
);

export const getCollaboratorById = createCachedSelector(
  collaborators,
  (_: IApplicationState, userId: string | null | undefined) => userId,
  (collaborators, userId): ICollaborator | undefined =>
    userId ? collaborators[0]?.collaborators.find((u) => u.id === userId) : undefined,
)((_: IApplicationState, userId) => userId || 'undefined');

export const getHasDemoModeEnabled = createSelector(getAdmin, (admin) => !!admin.demoMode?.enabled);
export const getSupplierCollaborators = createSelector(
  collaborators,
  (collaborators) => collaborators[0]?.collaborators || [],
);

export const getSupplierAchievementsNotApplicable = createSelector(
  [getSupplier],
  (supplier) => supplier?.achievementsNotApplicable,
);

export const getPhotosCounter = createSelector([getSupplier], (supplier) =>
  Number(supplier?.counters?.photos),
);

export const getVideoCounter = createSelector([getSupplier], (supplier) =>
  Number(supplier?.counters?.videos),
);

/**
 * Returns the current number of slots for additional counties.
 */
export const getAdditionalCountiesSlots = createSelector(
  [_getAdditionalAreasSlots, getIsVenue, getSupplierTier, (state) => state.auth.isAdmin],
  (additionalAreasSlots, isVenue, supplierTier, isAdmin) =>
    _getAdditionalCountiesSlots({
      additionalAreasSlots,
      isVenue,
      supplierTier,
      isAdmin,
    }),
);

/**
 * Returns maximum number of slots available for the supplier's tier.
 */
export const getAdditionalCountiesLimit = createSelector(
  [getIsVenue, getSupplierTier, (state) => state.auth.isAdmin],
  (isVenue, supplierTier, isAdmin) =>
    _getAdditionalCountiesLimit({
      isVenue,
      supplierTier,
      isAdmin,
    }),
);
