import {
  addedTeamChatGroupAnalytics,
  deletedTeamChatGroupAnalytics,
} from 'lib/team-chat/analytics/actions';

export enum TeamChatAnalyticsActionTypes {
  ADDED_CMS_CHAT_GROUP_ANALYTICS = '[Analytics] Added team chat group',
  DELETED_CMS_CHAT_GROUP_ANALYTICS = '[Analytics] Deleted team chat group',
  ADDED_ATTACHMENT_TO_CMS_CHAT_ANALYTICS = '[Analytics] Added attachment to team chat',
}

export type AddedTeamChatGroupAnalyticsAction = ReturnType<typeof addedTeamChatGroupAnalytics>;

export type DeletedTeamChatGroupAnalyticsAction = ReturnType<typeof deletedTeamChatGroupAnalytics>;
